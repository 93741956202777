import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { Button, Card, Grid, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import axios from "../../services/axios";
import MyThemeProvider from '../../services/MyTheme';

export default function MarkEntry({ dat, code }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: name === 'admission' ? value : Number(value),
    }));
  };

  function validate(obj) {
    const requiredFields = ['admission', 'class_id', 'schoolCode', 'exam_id', 'mark', 'outOf', 'subject_code', 'term_id', 'year'];
    for (let field of requiredFields) {
      if (!obj[field]) {
        enqueueSnackbar(`${field} is required`, { variant: 'error' });
        return false;
      }
    }
    if (Number(obj.mark) > Number(obj.outOf)) {
      enqueueSnackbar("Mark cannot be greater than Out Of", { variant: 'error' });
      return false;
    }
    return true;
  }

  useEffect(() => {
    setFormValues(prev => ({
      ...prev,
      subject_code: dat.subjectCode,
      term_id: dat.termId,
      exam_id: dat.examId,
      year: dat.year,
      class_id: dat.classId,
      schoolCode: code,
    }));
  }, [dat, code]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate(formValues)) {
      setLoading(true);
      try {
        await axios.post('/marks/add/mark', formValues);
        enqueueSnackbar('Marks updated successfully', { variant: 'success' });
        // Only clear 'admission' and 'mark' fields
        setFormValues(prev => ({ 
          ...prev, 
          admission: '', 
          mark: '' 
        }));
      } catch (error) {
        enqueueSnackbar(error.response?.data?.message || "Error adding Marks", { variant: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title> Enter Marks | e-marks </title>
      </Helmet>
      <MyThemeProvider>
        <Card>
          <Grid container justifyContent="center" sx={{ padding: 5 }}>
            <Grid item xs={12} sm={8} md={6}>
              <Stack spacing={3} component="form" onSubmit={handleSubmit}>
                <TextField
                  id="outOf"
                  name="outOf"
                  label="Out Of"
                  type="number"
                  required
                  value={formValues.outOf || ''}
                  onChange={handleInputChange}
                />
                <TextField
                  id="admission"
                  name="admission"
                  label="Admission"
                  autoFocus
                  required
                  value={formValues.admission || ''}
                  onChange={handleInputChange}
                />
                <TextField
                  id="mark"
                  name="mark"
                  label="Enter Mark"
                  type="number"
                  required
                  value={formValues.mark || ''}
                  onChange={handleInputChange}
                />
                
                <Stack direction="row" justifyContent="space-between">
                  <Button variant="outlined" onClick={() => navigate('/')}>
                    Cancel
                  </Button>
                  <LoadingButton loading={loading} type="submit" variant="contained">
                    Save
                  </LoadingButton>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </MyThemeProvider>
    </>
  );
}

MarkEntry.propTypes = {
  dat: PropTypes.shape({
    subjectCode: PropTypes.number,
    termId: PropTypes.number,
    examId: PropTypes.number,
    year: PropTypes.number,
    classId: PropTypes.number,
  }).isRequired,
  code: PropTypes.number.isRequired,
};