import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Card, Container, Stack, Grid, Box, Tab, Tabs, Typography, CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useSnackbar } from "notistack";

import Marks from "./Marks";
import DataCombo from "./Combo";
import MarkEntry from './MarkEntry';
import axios from "../../services/axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MarkTabs() {
  const [value, setValue] = useState(0);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedExam, setSelectedExam] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectedExam = (value) => setSelectedExam(value);
  const handleSelectedSubject = (value) => setSelectedSubject(value);
  const handleSelectedYear = (value) => setSelectedYear(value);
  const handleSelectedTerm = (value) => setSelectedTerm(value);
  const handleSelectedClass = (value) => setSelectedClass(value);

  const school = JSON.parse(localStorage.getItem("school"));
  const code = Number(school?.schoolCode);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/synch/combo/data?schoolCode=${code}`);
        setData(response.data);
      } catch (error) {
        enqueueSnackbar("Failed to fetch combo data", { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [code, enqueueSnackbar]);

  const selections = useMemo(() => ({
    classId: selectedClass?.classId,
    termId: selectedTerm?.termId,
    year: selectedYear?.year,
    subjectCode: selectedSubject?.subjectCode,
    examId: selectedExam?.examId
  }), [selectedClass, selectedTerm, selectedYear, selectedSubject, selectedExam]);

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title> Marks | e-marks </title>
      </Helmet>
      <Container sx={{ minWidth: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            Marks
          </Typography>
        </Stack>
        <Box sx={{ width: "100%", padding: 0, pt: 1 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid container spacing={2}>
              {["subjects", "exams", "classes", "terms", "years"].map((type) => (
                <Grid item xs={12} md={2} key={type}>
                  <DataCombo
                    onSelectedValue={{
                      subjects: handleSelectedSubject,
                      exams: handleSelectedExam,
                      classes: handleSelectedClass,
                      terms: handleSelectedTerm,
                      years: handleSelectedYear
                    }[type]}
                    dt={data[type]}
                    type={type}
                    title={`Select ${type.charAt(0).toUpperCase() + type.slice(1, -1)}`}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Card>
          <Box sx={{ width: "100%", padding: 0, pt: 1 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="mark tabs"
              >
                <Tab label="MarkList" {...a11yProps(0)} />
                <Tab label="Marks" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Marks dat={selections} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <MarkEntry dat={selections} code={code} />
            </TabPanel>
          </Box>
        </Card>
      </Container>
    </>
  );
}