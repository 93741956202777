import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { capitalCase } from "change-case";
import { useSnackbar } from "notistack";
import Iconify from "../../components/iconify";
import Scrollbar from "../../components/scrollbar";
import MarkListHead from "./MarkListHead";
import MyThemeProvider from "../../services/MyTheme";
import axios from "../../services/axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';

const TABLE_HEAD = [
  { id: "admission", label: "AdmNo", alignRight: false, firstColumn: true },
  { id: "mark", label: "Mark", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

export default function Marks({ dat }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMark, setSelectedMark] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const school = JSON.parse(localStorage.getItem("school"));
      const code = Number(school?.schoolCode);
      const response = await axios.get(
        `/marks/class/subject?schoolCode=${code}&examId=${Number(dat.examId)}&termId=${Number(dat.termId)}&classId=${Number(dat.classId)}&year=${Number(dat.year)}&subjectCode=${Number(dat.subjectCode)}`
      );
      setData(response.data);
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dat, enqueueSnackbar]);

  const handleClick = (event, mark) => {
    setAnchorEl(event.currentTarget);
    setSelectedMark(mark);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    setOpenDialog(true);
    handleClose();
  };

  const handleDeleteConfirm = async () => {
    try {
      const school = JSON.parse(localStorage.getItem("school"));
      const code = Number(school?.schoolCode);
      await axios.delete('/marks/delete/mark', {
        params: {
          admission: selectedMark.admission,
          term: dat.termId,
          year: dat.year,
          schoolCode: code,
          examId: dat.examId
        }
      });
      enqueueSnackbar('Mark deleted successfully', { variant: 'success' });
      fetchData(); // Refresh the list
    } catch (error) {
      enqueueSnackbar('Error deleting mark', { variant: 'error' });
    }
    setOpenDialog(false);
  };
   
  if (loading) {
    return (
      <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
        <CircularProgress />
      </Card>
    );
  }

  return (
    <MyThemeProvider>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <MarkListHead headLabel={TABLE_HEAD} />
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const { mark_id, mark, admission, name } = row;

                    return (
                      <TableRow hover key={mark_id} tabIndex={-1}>
                        <TableCell align="left">{admission}</TableCell>
                        <TableCell align="left">{mark}</TableCell>
                        <TableCell align="left">{capitalCase(name)}</TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleClick(event, row)}>
                            <Iconify icon={"eva:more-vertical-fill"} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {data.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                      No data found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[15, 30, 60]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDeleteClick} style={{ color: red[500] }}>
          <DeleteIcon style={{ marginRight: '8px', color: red[500] }} />
          Delete
        </MenuItem>
      </Menu>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this mark?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </MyThemeProvider>
  );
}

Marks.propTypes = {
  dat: PropTypes.shape({
    examId: PropTypes.number.isRequired,
    termId: PropTypes.number.isRequired,
    classId: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    subjectCode: PropTypes.number.isRequired,
  }).isRequired,
};