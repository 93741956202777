import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { capitalCase } from 'change-case';
import { Autocomplete, TextField, Box, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MyThemeProvider from '../../services/MyTheme';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '100%',
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    transition: theme.transitions.create(['top', 'font-size'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)',
  },
  '& .MuiInputBase-root': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['border-color', 'box-shadow', 'background-color'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
    },
  },
  '& .MuiAutocomplete-input': {
    fontSize: '0.75rem',
    padding: '7px 4px 7px 6px !important',
  },
  '& .MuiAutocomplete-option': {
    fontSize: '0.75rem',
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: '8px 10px',
  },
  '& .MuiAutocomplete-tag': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: '12px',
    padding: '3px 8px',
    fontSize: '0.6875rem',
    height: '22px',
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const OptionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(0.5, 1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function DataCombo({ title, type, dt, onSelectedValue }) {
  const [selectedValue, setSelectedValue] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (dt?.length > 0 && !selectedValue) {
      setSelectedValue(dt[0]);
      onSelectedValue(dt[0]);
    }
  }, [dt, onSelectedValue, selectedValue]);

  const labelMap = useMemo(() => ({
    years: 'year',
    terms: 'termName',
    classes: 'initials',
    subjects: 'subjectName',
    exams: 'examName',
  }), []);

  const keyMap = useMemo(() => ({
    years: 'year',
    terms: 'termID',
    classes: 'classID',
    subjects: 'subjectCode',
    exams: 'examId',
  }), []);

  const getOptionLabel = (option) => capitalCase(option[labelMap[type]]?.toString() || '');

  const getOptionSelected = (option, value) => option[keyMap[type]] === value[keyMap[type]];

  const handleChange = (event, value) => {
    setSelectedValue(value);
    onSelectedValue(value);
  };

  return (
    <MyThemeProvider>
      <StyledAutocomplete
        options={dt || []}
        value={selectedValue}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
        renderInput={(params) => (
          <TextField 
            {...params} 
            label={title} 
            variant="outlined" 
            fullWidth
            InputLabelProps={{
              style: { fontSize: '0.75rem' }
            }}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: '0.75rem' }
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <OptionWrapper>
              <Typography variant="caption">{getOptionLabel(option)}</Typography>
              <Typography variant="caption" color="text.secondary">
                {option[keyMap[type]]}
              </Typography>
            </OptionWrapper>
          </li>
        )}
        ListboxProps={{
          style: {
            maxHeight: '40vh',
          },
        }}
        size="small"
      />
    </MyThemeProvider>
  );
}

DataCombo.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['years', 'terms', 'classes', 'subjects', 'exams']).isRequired,
  dt: PropTypes.array,
  onSelectedValue: PropTypes.func.isRequired,
};

export default DataCombo;